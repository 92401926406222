import Breadcrumb from "../../Common/Breadcrumb/Breadcrumb";
import Footer from "../../Common/Footer/Footer";
import styles from "./BookingConfirm.module.scss";

const BookingConfirm = () => {
	return (
		<>
			<Breadcrumb title="" link="" />

      

			<Footer />
		</>
	);
};

export default BookingConfirm;
