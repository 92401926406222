import LoginContainer from "./LoginContainer";

const Login = () => {
  
  console.log('Login Page')
	return (
    <LoginContainer/>
	);
};

export default Login;
