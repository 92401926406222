import RegisterContainer from "./RegisterContainer";

const Register = () => {
  
  console.log('Register Page')
	return (
    <RegisterContainer/>
	);
};

export default Register;
