const TextSingle = () => {
	return (
		<>
			<h3>Tour Overview</h3>

			<p>
				Sed ut perspiciatis unde omnis iste natus error sit voluptatem
				accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
				illo inventore veritatis et quasi architecto beatae vitae dicta sunt
				explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
				odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
				voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
				quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam
				eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
				voluptatem.
			</p>

			<h3>Included And Excluded</h3>
			<p>
				At vero eos et accusamus et iusto odio dignissimos ducimus qui
				blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
				et quas molestias excepturi sint occaecati cupiditate non provident,
				similique sunt in culpa qui officia deserunt mollitia animi, id est
				laborum et dolorum fuga.
			</p>
		</>
	);
};

export default TextSingle;
