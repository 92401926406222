//api
export const TOURS = "API/TOURS";
export const TOUR = "API/TOUR";
export const TOP_TOURS = "API/TOP_TOURS";
export const DESTINATIONS = "API/DESTINATIONS";
export const REVIEWS = "API/REVIEWS";
export const CLEAR_TOURS = "USER/CLEAR_TOURS";

//user
export const USER = "USER/USER";
export const CLEAR_USER = "USER/CLEAR_USER";


